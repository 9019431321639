import { Box, Button, Divider, Flex, Heading, Icon, Image, Spacer, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaToolbox } from 'react-icons/fa6';
import { FiLinkedin } from 'react-icons/fi';
import { GiOpenBook } from 'react-icons/gi';
import { GoProject } from 'react-icons/go';
import { MdAlternateEmail, MdWorkHistory } from 'react-icons/md';
import ProfPic2 from '../assets/petros-shot-transparent-2.png';
import { IMG_TOP_MAX, IMG_TOP_MIN, IMG_W_MAX, IMG_W_MIN } from '../constants';
import { ActiveWindows } from '../types';
import { EMAIL_URL, LN_URL } from './Footer';
import AboutMePopover from './AboutMePopover';

interface ISkillBtnProps {
    rightIcon?: any;
    leftIcon?: any;
    isActive?: boolean;
    isDisabled?: boolean;
    onClick: any;
    children: any;
}

const SkillBtn: React.FC<ISkillBtnProps> = ({ rightIcon, leftIcon, isActive, isDisabled, onClick, children }) => {
    return (
        <Button
            w={160}
            rightIcon={rightIcon}
            leftIcon={leftIcon}
            colorScheme={isActive ? 'telegram' : 'telegram'}
            variant={isActive ? 'solid' : 'outline'}
            boxShadow='lg'
            p={0}
            m={0}
            isDisabled={isDisabled}
            cursor='pointer'
            onClick={onClick}>
            { children }
        </Button>
    )
}

// eslint-disable-next-line
const witcher = 'invert(7100%)';

const TopArea: React.FC<{ activeWindow: ActiveWindows, onSetWindow: any }> = ({ activeWindow, onSetWindow }) => {
    const openLink = (link: string): void => {
        window.open(link, '_blank');
    }

    const iconProps = {
        w: '20px',
        h: '20px',
        cursor: 'pointer',
        mr: 5
    }

    return (
        <Flex direction='column' w='100%' alignItems='center'>
            <Flex direction='column' textAlign='center'>
                <Box>
                    <Text as={Heading} size='2xl' fontWeight='bold' mb={1}>Petros G. Sideris</Text>
                    <Icon as={FiLinkedin} {...iconProps} color='linkedin.800' onClick={() => openLink(LN_URL) } />
                    <Icon as={MdAlternateEmail} {...iconProps} color='facebook.600' onClick={() => openLink(EMAIL_URL) } />
                </Box>
                <Divider size='xl' colorScheme='green' />
                <Text size='lg' color='gray.900' textDecoration='auto' mt={1}>
                    Craftsman | Engineer | CTO | Entrepreneur
                </Text>
                <AboutMePopover />
            </Flex>

            <Flex mt={[100, 100, 20, 20]} direction='row' gap={[3, 3, 20, 20]}>
                <Flex direction='column'>
                    <SkillBtn leftIcon={<Icon h='30px' w='30px' as={MdWorkHistory} />}
                              onClick={() => onSetWindow(ActiveWindows.Experience)}
                              isActive={activeWindow === ActiveWindows.Experience}>
                        Experience
                    </SkillBtn>
                    <Spacer />
                    <SkillBtn leftIcon={<Icon h='30px' w='30px' as={FaToolbox} />}
                              onClick={() => onSetWindow(ActiveWindows.Skills)}
                              isActive={activeWindow === ActiveWindows.Skills}>
                        Skills
                    </SkillBtn>
                </Flex>

                <Box
                    transition={`width 0.3s, height 0.3s`}
                    width={['1px', '1px', '230px', '230px']}
                    h={['100px', '100px', '150px', '150px']} />

                <Flex direction='column'>
                    <SkillBtn rightIcon={<Icon h='30px' w='30px' as={GoProject} />}
                              isDisabled
                              onClick={() => onSetWindow(ActiveWindows.Projects)}
                              isActive={activeWindow === ActiveWindows.Projects}>
                        Projects
                    </SkillBtn>
                    <Spacer />
                    <SkillBtn rightIcon={<Icon h='30px' w='30px' as={GiOpenBook} />}
                              isDisabled
                              onClick={() => onSetWindow(ActiveWindows.Education)}
                              isActive={activeWindow === ActiveWindows.Education}>
                        Education
                    </SkillBtn>
                </Flex>
            </Flex>

            <Image
                transition={`width 0.3s, height 0.3s, top 0.3s`}
                src={ProfPic2}
                sx={{ filter: 'grayscale(100%) blur(0.1px)' }}
                width={[IMG_W_MIN,  IMG_W_MIN, IMG_W_MAX, IMG_W_MAX]}
                position='absolute'
                top={[IMG_TOP_MAX, IMG_TOP_MAX, IMG_TOP_MIN, IMG_TOP_MIN]} />
        </Flex>
    )
}
export default TopArea;
