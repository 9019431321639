import { AbsoluteCenter, Box, Center, Divider, Flex, Grid, GridItem, Heading, HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import skills, { SkillType } from '../../helpers/skills';
import SkillCategory from './SkillCategory';

const Skills = () => {
    const langSkills = skills.filter(({ type }) => type === SkillType.LANG);
    const fmwSkills = skills.filter(({ type }) => type === SkillType.FMW);
    const engineerSkills = skills.filter(({ type }) => type === SkillType.ENGINEER);
    const toolSkills = skills.filter(({ type }) => type === SkillType.TOOL);
    const infraSkills = skills.filter(({ type }) => type === SkillType.INFRA);
    const peopleSkills = skills.filter(({ type }) => type === SkillType.PEOPLE);
    const manageSkills = skills.filter(({ type }) => type === SkillType.MANAGE);

    return (
        <Flex direction='column' margin='0 auto' w='100%' alignItems='center' px={4}>
            <Heading size='md' fontWeight='bold' userSelect='none' mt={2} mb={4}>
                Expert Skills
            </Heading>
            <Stack gap={0}>
                <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 2fr)' gap={2}>
                    <SkillCategory name={SkillType.LANG} skills={langSkills} rows={2} cols={1} />
                    <SkillCategory name={SkillType.FMW} skills={fmwSkills} cols={1} />
                    <SkillCategory name={SkillType.ENGINEER} skills={engineerSkills} cols={1} />
                    <SkillCategory name={SkillType.TOOL} skills={toolSkills} cols={1} />
                    <SkillCategory name={SkillType.INFRA} skills={infraSkills} cols={1} />
                </Grid>
                <Box position='relative' padding='10' w='100%' h={20}>
                    <Divider colorScheme='blue' />
                    <Heading textAlign='center' px='4' size='sm'>
                        Soft Skills
                    </Heading>
                </Box>
                <Center>
                    <Grid templateColumns='repeat(2, 1fr)' gap={1} w={'50%'}>
                        <SkillCategory name={SkillType.PEOPLE} skills={peopleSkills} cols={1} />
                        <SkillCategory name={SkillType.MANAGE} skills={manageSkills} cols={1} />
                    </Grid>
                </Center>
            </Stack>

        </Flex>
    );
};

export default Skills;
