import React from 'react';
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import ExpandableText from '../../components/ExpandableText';

interface IExperienceEntry {
    description: string;
    title: string;
    from: string;
    to?: string;
    companyName: string;
    companyUrl?: string | null;
    companyLogo?: any;
}

const ExperienceEntry: React.FC<IExperienceEntry> = ({ description, title, from, to, companyLogo, companyUrl, companyName }) => {

    return (
        <Flex direction='column' maxW='600px' mb={5}>
            <Flex>
                {companyLogo && <Image src={require(`../../assets/logos/${companyLogo}`)} w='60px' h='60px' mr={2} /> }
                {!companyLogo && <Box w='60px' mr={2}/> }
                <Flex direction='column'>
                    <Flex>
                        <Text fontSize='md' fontWeight='bold' w='20em'>
                            { title }
                        </Text>
                        <Text fontSize='sm' lineHeight={2.2} color='gray.600'>
                            { from } - { to ? to : '' }
                        </Text>
                    </Flex>
                    <Flex fontSize='md'>
                        <Text fontWeight='bold' size='sm'>
                            { companyUrl && <Link isExternal href={companyUrl}>{ companyName }</Link> }
                            { !companyUrl && <Text>{ companyName }</Text> }
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
            <ExpandableText noOfLines={3} text={description} />
        </Flex>
    )
}

export default ExperienceEntry;
