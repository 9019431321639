import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Pakito from './views/Pakito';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='' element={<Pakito />} />
                <Route path='/p' element={<Pakito />} />
                {/*<Route path={'*'} element={<NotFound />}/>*/}
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;
