import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500-italic.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600-italic.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700-italic.css';
import '@fontsource/montserrat/700.css';

import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500-italic.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700-italic.css';
import '@fontsource/roboto/700.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import AppRoutes from './AppRoutes';

import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Suspense fallback='loading'>
            <ChakraProvider theme={theme} resetCSS={true}>
                <AppRoutes />
            </ChakraProvider>
        </Suspense>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
