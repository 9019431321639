import { ISkill } from '../../helpers/skills';
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

interface SkillEntryProps {
    skill: ISkill
}

const SkillEntry: React.FC<SkillEntryProps> = ({ skill }) => {
    return (
        <Text _hover={{ textDecoration: 'underline' }} cursor='auto'>
            · { skill.name }
        </Text>
    );
}

export default SkillEntry;
