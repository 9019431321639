import { Box, Button, Text } from '@chakra-ui/react';
import React, { memo, useState } from 'react';
import ReactMarkdown from 'react-markdown';

interface Props {
    noOfLines?: number;
    text: string;
}

export const ExpandableText = memo(({ noOfLines = 3, text }: Props) => {
    const [expandedCount, setExpandedCount] = useState<number | undefined>(noOfLines);

    const handleToggle = () => setExpandedCount(expandedCount ? undefined : noOfLines);

    return (
        <Box display='inline-block' as='span'>
            <Box noOfLines={expandedCount}>
                <Text color='gray.600' pr={4} fontSize='sm' mt={2} pl='65px'>
                    <ReactMarkdown>
                        { text }
                    </ReactMarkdown>
                </Text>
            </Box>
            <Box display={text.length >= 120 ? 'block' : 'none'}>
                <Button
                    colorScheme='slate'
                    fontSize='sm'
                    fontWeight='bold'
                    float='right'
                    mr={10}
                    size='sm'
                    textDecoration='underline'
                    variant='link'
                    onClick={handleToggle}>
                    {!expandedCount ? 'less' : 'more'}
                </Button>
            </Box>
        </Box>
    );
});

export default ExpandableText;