import { Box, Flex } from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import bg from '../assets/bg3.png';
import { Footer } from '../components/Footer';
import TopArea from '../components/TopArea';
import { IMG_TOP_MAX, IMG_TOP_MIN, IMG_W_MAX, IMG_W_MIN } from '../constants';
import Skills from './Skills';
import { ActiveWindows } from '../types';
import Experience from './Experience';


const Pakito = () => {
    const [currentscroll, setCurrentScroll] = useState<number>(0);
    const [pageHeight, setPageHeight] = useState<number>(window.innerHeight);
    const [activeWindow, setActiveWindow] = useState<ActiveWindows>(ActiveWindows.Experience);

    const handleScroll = useCallback(debounce(() => {
        const currentScrollY = window.scrollY;
        if (currentScrollY < (pageHeight/10)) {
            setCurrentScroll(currentScrollY);
        }
    }, 100), [pageHeight]);

    useEffect(() => {
        const handleResize = () => {
            setPageHeight(window.innerHeight);
        };
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <Box p={0} m={0} minW={375}>
            <Box
                bgRepeat='no-repeat'
                bgPos='center 20%'
                bgSize='cover'
                h={{
                    base: `${IMG_W_MIN + IMG_TOP_MAX}px`,
                    sm: `${IMG_W_MIN + IMG_TOP_MAX}px`,
                    md: `${IMG_W_MIN + IMG_TOP_MAX}px`,
                    lg: `${IMG_W_MAX + IMG_TOP_MIN}px`,
                    xl: `${IMG_W_MAX + IMG_TOP_MIN}px`,
                }}
                minW={375}
                position='fixed'
                objectFit='cover'
                top={0}
                w='100%'
                _before={{
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bgRepeat: 'no-repeat',
                    bgPos: 'center 30%',
                    bgImage: bg,
                    filter: 'grayscale(100%) blur(2px)',
                    zIndex: -1,
                }}
                zIndex={1}>
                <TopArea activeWindow={activeWindow} onSetWindow={setActiveWindow} />
            </Box>

            <Flex
                sx={{
                    boxShadow: '0 -1px 20px -1px rgba(0, 0, 0, 0.1), 0 -3px 5px -1px rgba(0, 0, 0, 0.06)'
                }}
                bgGradient='linear(to-b, white, gray.300)'
                position='absolute'
                top={{
                    base: `${IMG_W_MIN + IMG_TOP_MAX - currentscroll - 40}px`,
                    sm: `${IMG_W_MIN + IMG_TOP_MAX - currentscroll - 40}px`,
                    md: `${IMG_W_MIN + IMG_TOP_MAX - currentscroll - 40}px`,
                    lg: `${IMG_W_MAX + IMG_TOP_MIN - currentscroll - 40}px`,
                    xl: `${IMG_W_MAX + IMG_TOP_MIN - currentscroll - 40}px`,
                }}
                minH={`calc(73vh - ${currentscroll}px)`}
                minW={'100vw'}
                zIndex={5}
                pb={100}>
                { activeWindow === ActiveWindows.Experience && <Experience /> }
                { activeWindow === ActiveWindows.Skills && <Skills /> }
                <Footer />
            </Flex>
        </Box>
    );
};

export default Pakito;
