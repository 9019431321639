import { Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import FooterHeading from './FooterHeading';
import { FooterLink } from './FooterLink';

export const EMAIL_URL = `mailto:pgsideris@gmail.com?subject=Hello&body=I want to say hi`;
export const LN_URL = 'https://www.linkedin.com/in/pgsideris';

export const Footer: React.FC = () => (
    <Flex dir='row' w='100%' mt={10} pt={10} mb={4} position='absolute' bottom={0} px={[1, 20, 20, 20]}>
        <HStack align='start' gap='16px'>
            <Text
                as='span'
                fontWeight={400}
                fontSize='12px'
                lineHeight={1.625}
                color='gray.500'>
                &copy; {new Date().getFullYear()} Petros G. Sideris
            </Text>
        </HStack>

        <Spacer />

        <VStack align='start' gap='16px'>
            <FooterHeading>Connect with me</FooterHeading>
            <FooterLink href={LN_URL} isExternal>
                LinkedIn
            </FooterLink>
        </VStack>

        <Spacer />

        <VStack align='start' gap='16px'>
            <FooterHeading>Contact</FooterHeading>
            <FooterLink href={EMAIL_URL}>
                Send me an email
            </FooterLink>
        </VStack>
    </Flex>
);
