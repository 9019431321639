const Button = {
    baseStyle: {
        borderRadius: '50px',
        px: '14px',
        py: '8px',
        track: {
            _focus: {
                boxShadow: 'none'
            }
        }
    },
    // Some sizes are commented out because there might be an undesirable difference in button theme.
    sizes: {
        '2xl': {
            fontSize: 'lg',
            fontWeight: 600,
            px: '28px',
            py: '16px',
        },
        'xl': {
            fontSize: 'md',
            fontWeight: 600,
            px: '20px',
            py: '12px',
        },
        'lg': {
            fontSize: 'md',
            fontWeight: 600,
            px: '18px',
            py: '10px',
        },
        'md': {
            fontSize: 'sm',
            fontWeight: 600,
            px: '16px',
            py: '10px',
        },
        'sm': {
            fontSize: 'sm',
            fontWeight: 600,
            px: '14px',
            py: '8px',
        },
        'xs': {
            fontSize: 'sm',
            fontWeight: 600,
            px: '14px',
            py: '8px',
        },
    },
};

export default Button;
