import { Button, Center, Flex, Heading, Icon, Skeleton, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import experience, { otherExperience } from '../../helpers/experience';
import ExperienceEntry from './ExperienceEntry';

const Experience: React.FC = () => {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const [showOthers, setShowOthers] = useState<boolean>(false);
    const [toggling, setToggling] = useState<boolean>(false);

    const onToggle = () => {
        if (isOpen) {
            onClose();
        } else {
            onOpen();
        }
        setToggling(true);
        setTimeout(() => setToggling(false), 900);
    }

    const onToggleOthers = () => {
        setShowOthers(!showOthers);
        setToggling(true);
        setTimeout(() => setToggling(false), 500);
    }

    const visibleExperience = useMemo(() => {
        const displaySet = showOthers ? otherExperience : experience;
        return displaySet
            .filter(job => job.keep || (!job.keep && isOpen))
            .map((job, idx) =>  <ExperienceEntry {...job} key={`job-${idx}`} />);
    }, [showOthers, isOpen]);

    return (
        <Flex direction='column' margin={'0 auto'} w='100%' alignItems='center' px={4}>
            <Center gap={2}  mb={8} mt={2} w='100%' textAlign='center'>
                <Spacer />
                { !showOthers &&
                    <>
                        <Heading size='md' fontWeight='bold' userSelect='none'>
                            Experience
                        </Heading>
                        <Button
                            variant={'link'}
                            colorScheme='telegram'
                            size='xs'
                            textDecoration='underline'
                            userSelect='none'
                            onClick={onToggle}>
                            { !isOpen && 'show all' }
                            { isOpen && 'show fewer' }
                        </Button>
                    </>
                }
                { showOthers && <Heading size='md' fontWeight='bold' userSelect='none'>Advisory and Other</Heading> }
                <Spacer />
                <Button variant='link' onClick={onToggleOthers} size='xs' colorScheme='blue'>
                    { showOthers && <Icon as={MdArrowBack} /> }
                    { !showOthers ? 'Advisory and Other ' : ' Experience' }
                    { !showOthers && <Icon as={MdArrowForward} /> }
                </Button>
                <Spacer />
            </Center>
            <Stack>
                { toggling &&
                    <>
                        <Skeleton isLoaded={!toggling} h={20}/>
                        <Skeleton isLoaded={!toggling} h={20}/>
                    </>
                }
                <Skeleton isLoaded={!toggling}>
                    { visibleExperience }
                </Skeleton>
            </Stack>
        </Flex>
    );
}

export default Experience;
