import { GridItem, Heading } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import React from 'react';
import { ISkill } from '../../helpers/skills';
import SkillEntry from './SkillEntry';

interface ISkillCategory {
    name: string;
    skills: ISkill[];
    cols?: number;
    rows?: number;
}
const SkillCategory: React.FC<ISkillCategory> = ({ skills, name, cols, rows }) => {
    return (
        <GridItem rowSpan={rows} colSpan={cols}>
            <Heading size='xs' mb={1}>
                { capitalize(name.split('_').join(' ')) }
            </Heading>
            { skills.map(skill => <SkillEntry skill={skill} key={skill.name} />) }
        </GridItem>
    )
}

export default SkillCategory;
