import {
    Button, Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import React from 'react';

const AboutMePopover = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button onClick={onOpen} colorScheme='cyan' variant='link'>About me</Button>

            <Modal isOpen={isOpen} onClose={onClose} size='full'>
                <ModalOverlay
                    bg='none'
                    backdropFilter='auto'
                    backdropInvert='80%'
                    backdropBlur='2px' />
                <ModalContent>
                    <ModalHeader>A bit about me</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody textAlign='left'>
                        <Text>
                            I grew up in Greece in a coastal city, where I studied Informatics & Telecommunications Engineering at the University of Western Macedonia.
                        </Text>
                        <Text my={4}>
                            In 2012 I moved to Sweden where I studied Complex Adaptive Systems at Chalmers University of Technology.
                            I left this degree after 1.5 years to pursue a career in software engineering.
                        </Text>

                        <Text my={4}>
                            In 2015 I worked for 2 years as a Software Engineer at a startup in Cambridge, UK.
                            After that I moved to London where I worked for 2 years of a few startups and then 5 years in finance (Goldman Sachs, Hedge Funds).
                        </Text>

                        <Text my={4}>
                           In 2022 I started a Web3 startup with a friend, and in late 2022 I moved to Berlin.
                        </Text>

                        <Text my={4}>
                            Currently, I advise and work with different Web3 projects, and I'm also working on a few of my own.
                        </Text>
                        <Divider />
                        <Text my={4}>
                            I am a natural problem solver, and I enjoy working with people. <br/>
                            I love learning new things, and I'm always curious about how things work. <br/>
                            I'm also a fan of the outdoors, and I love hiking, and seaside camping.
                        </Text>
                        <Text my={4}>
                           Chat to me about: <br/>
                            - Software Engineering <br/>
                            - Reading <br/>
                            - Philosophy <br/>
                            - Psychology <br/>
                            - Wine <br/>
                            - Cooking <br/>
                            - Anime <br/>
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Ok, Cool
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
};

export default AboutMePopover;
